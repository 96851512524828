import React from 'react';
import { RiCustomerService2Full, RiCustomerService2Fill } from 'react-icons/ri';
import { MdOutlineTravelExplore } from 'react-icons/md';
import Tugu from '../assets/tugu.jpg';
const Search = () => {
  return (
    <div name='sejarah' className='max-w-[1240px] mx-auto grid lg:grid-cols-3 gap-4 px-4 py-16'>
      <div className='lg:col-span-2 flex flex-col justify-evenly'>
        <div>
          <h2>TUGU STATUS QUO KARAG HILLS</h2>
          <p className='py-4'>
          Pada tanggal 1 Agustus 1947, Karag menjadi saksi bisu dengan adanya Perjanjian Renville
          KTN (Komisi Tiga Negara) datang ke Purbalingga untuk menentukan garis demarkasi. 
          Berdasarkan hasil keputusan KTN, Belanda dan Indonesia, Garis Demarkasi ditetapkan di desa Timbang tepatnya di Karag. 
          Garis Demarkasi mengakibatkan perubahan signifikan bagi pertahanan TNI di wilayah Purbalingga.
          Sesudah penetapan Garis Demarkasi, pertahanan TNI terpaksa mundur sejauh 2 Km dari garis semula dengan 
          demikian daerah sebelah barat garis Status Quo menjadi wilayah Belanda (Purbalingga) sedangkan daerah sebelah Timur 
          Status Quo menjadi wilayah Indonesia (Banjarnegara).
          Tugu Status Quo sebagai penanda hasil perjanjian Renville tersebut berada di Karag desa Timbang, Kecamatan Kejobong, Kabupaten Purbalingga.
          </p>
        </div>
      </div>
      <div className='max-w-[1240px] mx-auto py-16 px-4 text-center'>
        <img className='w-full h-full object-cover' src={Tugu} alt="/" />
      </div>
    </div>
  );
};

export default Search;
