import React from 'react'

import kudalumping from '../assets/kudalumping.jpeg';
import anakmi from '../assets/anakmi.jpeg';
import anaksd from '../assets/anaksd.jpeg';
import anaksmp from '../assets/anaksmp.jpeg';
import kunjungan from '../assets/kunjungan.jpeg';
import jenang from '../assets/jenang.jpg';
import SelectsCard from './SelectsCard';
const Selects = () => {
  return (
    <div name='view' className='max-w-[1240px] mx-auto px-4 py-16 grid sm:grid-cols-2 lg:grid-cols-3 gap-4'>

      <SelectsCard bg={kudalumping} text='Kuda Lumping' />
      <SelectsCard bg={anakmi} text='Jalan sehat anak-anak MI' />
      <SelectsCard bg={anaksd} text='Jalan sehat anak-anak SD' />
      <SelectsCard bg={anaksmp} text='Jalan sehat anak-anak SMP' />
      <SelectsCard bg={kunjungan} text='Kunjungan Dinporapar' />
      <SelectsCard bg={jenang} text='Jenang Makanan Khas' />


    </div>
  )
}

export default Selects