import React, { useState } from 'react';
import { BsPerson } from 'react-icons/bs';
import { BiSearch } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';
import { HiOutlineMenuAlt4 } from 'react-icons/hi';
import { Link } from 'react-scroll'
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaPinterest,
  FaYoutube,
} from 'react-icons/fa';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [logo, setLogo] = useState(false)
  const handleNav = () => {
    setNav(!nav);
    setLogo(!logo)
  };

  return (
    <div className='flex w-full justify-between items-center h-20 px-4 absolute z-10 text-white'>
      <div>
        <h1 onClick={handleNav} className={logo ? 'hidden' : 'block'}>KARAG HILLS.</h1>
       
      </div>
      <ul className='hidden md:flex'>
        <Link to='home' smooth={true} duration={500}>
        <li className='cursor-pointer'>Home</li>
        </Link>
        <Link to='wahana' smooth={true} duration={500}>
        <li className='cursor-pointer'>Wahana</li>
        </Link>
        <Link to='view' smooth={true} duration={500}>
        <li className='cursor-pointer'>View</li>
        </Link>
        <Link to='sejarah' smooth={true} duration={500}>
        <li className='cursor-pointer'>Sejarah</li>
        </Link>
      </ul>

      {/* Hamburger */}
      <div onClick={handleNav} className='md:hidden z-10'>
        {nav ? <AiOutlineClose className='text-black' size={20} /> : <HiOutlineMenuAlt4 size={20} />}
      </div>

      {/* Mobile menu dropdown */}
      <div onClick={handleNav} className={nav ? 'absolute text-black left-0 top-0 w-full bg-gray-100/90 px-4 py-7 flex flex-col' : 'absolute left-[-100%]'}>
        <ul>
          <h1>KARAG HILLS.</h1>
          <li className='border-b cursor-pointer'>Home</li>
          <Link to='wahana' smooth={true} duration={500}>
          <li className='border-b cursor-pointer'>Wahana</li>
          </Link>
          <Link to='view' smooth={true} duration={500}>
          <li className='border-b cursor-pointer'>View</li>
          </Link>
          <Link to='sejarah' smooth={true} duration={500}>
          <li className='border-b cursor-pointer'>Sejarah</li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
