import React from 'react';
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaPinterest,
  FaYoutube,
} from 'react-icons/fa';
import { Link } from 'react-scroll'
const Footer = () => {
  return (
    <div className='w-full bg-gray-100 py-16'>
      <div className='max-w-[1240px] mx-auto flex flex-col px-4'>
        <div className='sm:flex text-center justify-between items-center'>
          <h1>KARAG HILLS.</h1>
          <div className='flex justify-between w-full sm:max-w-[180px] my-4 pr-4'>
            <a target='_blank' href='https://web.facebook.com/karaghills'>
              <FaFacebook className='icon' />
            </a>
            <a target='_blank' href='https://www.youtube.com/@PesonaKaragHills'>
              <FaYoutube className='icon' />
            </a>
            <a target='_blank' href='https://www.instagram.com/pesonakaraghills/'>
              <FaInstagram className='icon' />
            </a>
          </div>
        </div>
        <div className='flex justify-between'>

          <p className='pt-4'>Desa Wisata paling indah di ujung timur kabupaten Purbalingga</p>
        
          
          <ul className='text-right lg:flex'>
            <Link to='home' smooth={true} duration={500}>
            <li className='cursor-pointer'>Home</li>
            </Link>
            <Link to='wahana' smooth={true} duration={500}>
            <li className='cursor-pointer'>Wahana</li>
            </Link>
            <Link to='view' smooth={true} duration={500}>
            <li className='cursor-pointer'>View</li>
            </Link>
            <Link to='sejarah' smooth={true} duration={500}>
            <li className='cursor-pointer'>Sejarah</li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
