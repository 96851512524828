import React from 'react'

import gokart from '../assets/gokart.jpg';
import zoomouttimur from '../assets/zoomouttimur.jpeg';
import restozoomout from '../assets/restozoomout.jpeg';
import spotselfie from '../assets/spotselfie.jpeg';
import tugumushola from '../assets/tugumushola.jpeg';

const Destinations = () => {
  return (
    <div name='wahana' className='max-w-[1240px] mx-auto py-16 px-4 text-center'>
      <h1>Fasilitas dan Wahana</h1>
      <p className='py-4'>Pesona Desa Wisata di ujung Timur Purbalingga</p>
      <div className='grid grid-rows-none md:grid-cols-5 py-4 gap-2 md:gap-4'>
        <div class="bg-red-100 relative w-full h-full object-center scale-120 hover:z-50 hover:scale-150 ease-in duration-500">
          <img className='' src={gokart} alt="/" />
          <div class="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex justify-center items-center text-4xl text-white font-semibold">Gokart for Kids</div>
        </div>
        <div class="bg-red-100 relative w-full h-full object-center scale-120 hover:z-50 hover:scale-150 ease-in duration-500">
          <img className='' src={restozoomout} alt="/" />
          <div class="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex justify-center items-center text-4xl text-white font-semibold">Caffe & Resto</div>
        </div>
        <div class="bg-red-100 relative w-full h-full object-center scale-120 hover:z-50 hover:scale-150 ease-in duration-500">
          <img className='' src={spotselfie} alt="/" />
          <div class="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex justify-center items-center text-4xl text-white font-semibold">Spot Selfie</div>
        </div>
        <div class="bg-red-100 relative w-full h-full object-center scale-120 hover:z-50 hover:scale-150 ease-in duration-500">
          <img className='' src={tugumushola} alt="/" />
          <div class="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex justify-center items-center text-4xl text-white font-semibold">Tugu Status Quo</div>
        </div>
        <div class="bg-red-100 relative w-full h-full object-center scale-120 hover:z-50 hover:scale-150 ease-in duration-500">
          <img className='' src={zoomouttimur} alt="/" />
          <div class="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex justify-center items-center text-3xl text-white font-semibold">Playground & Rest Area</div>
        </div>
      </div>
    </div>
  )
}

export default Destinations