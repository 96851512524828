import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import beachVid from '../assets/beachVid.mp4';

const Hero = () => {
  return (
    <div name='home' className='w-full h-screen relative'>
      <video
        className='w-full h-full object-cover'
        src={beachVid}
        autoPlay
        loop
        muted
      />
      <div className='absolute w-full h-full top-0 left-0 bg-gray-900/30'></div>
      <div className='absolute top-0 w-full h-full flex flex-col justify-center text-center text-white p-4'>
        <h1>PESONA KARAG HILLS</h1>
        <h3 className='py-4 pb-16'>Sirkuit Gokart untuk Anak - Caffe & Resto dengan Pemandangan Paling Indah</h3>

        <a target="_blank" href="https://www.google.com/maps/place/Karag+Hill/@-7.3988352,109.5456431,16z/data=!4m15!1m8!3m7!1s0x2e6554ec084b2745:0x5027a76e3551e90!2sTimbang,+Kejobong,+Purbalingga+Regency,+Central+Java!3b1!8m2!3d-7.4010562!4d109.5359821!16s%2Fg%2F122_gcwb!3m5!1s0x2e65556610e4621b:0x8ecd4bdbd7ed2fe4!8m2!3d-7.3973453!4d109.5480268!16s%2Fg%2F11s2q1x607?entry=ttu">
          <button-s>GASSSS KUYY</button-s>
        </a>
        <div className='pt-32'>
          <h2>SELAMAT DATANG TIM PENILAI DESA WISATA</h2>
          <h3>DINPORAPAR KABUPATEN PURBALINGGA</h3>
        </div>

      </div>
    </div>
  );
};

export default Hero;
